'use client'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Toolbar from '@mui/material/Toolbar'
import { Link } from 'ui/components/Link'
import { Stack } from '@mui/material'
import { UserButton } from 'auth/clerk'
import { Logo } from '@/components/Logo'

export function PrivateAppTopBar() {
  return (
    <AppBar
      component="nav"
      elevation={0}
      position="relative"
      sx={{
        borderBottom: (theme) => `1px solid ${theme.palette.primary.main}`,
      }}
    >
      <Container maxWidth="xl">
        <Toolbar sx={{ gap: 2 }}>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <Logo maxSize="xs" />
          </Box>
          <Stack
            direction="row"
            sx={{
              flexGrow: 1,
            }}
          >
            <Link href="/projects" underline="hover" variant="h4">
              All Projects
            </Link>
          </Stack>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <UserButton />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
